import * as React from "react";
import { Link } from "gatsby-theme-material-ui";
import { StaticImage } from "gatsby-plugin-image";
import Box from '@mui/material/Box';
import Layout from "../components/layout";
import Seo from "../components/seo";
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ChartLayout from "../components/ChartStructure"
import LifeNumber from "../components/LifeNumber"
import useMediaQuery from '@mui/material/useMediaQuery';
import TextField from '@mui/material/TextField';
import { useTheme, createTheme } from '@mui/material/styles';
import { useState } from 'react'
import DatePicker from '../components/DatePicker'
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { NumberChip as Chip } from '../components/Chip';
import MobileNumber from "../components/MobileNumber"
import { East } from '@mui/icons-material/';
import NumberChart from "../components/NumberChart"
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { ThemeProvider } from '@mui/styles'
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Parse from '../vendor/Parse'
import PostList from '../components/PostList'
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import Menu from '../components/Menu'
import ThreeCol from '../components/ThreeColLayout'
import useAuth from "../hooks/userAuth";
import { graphql, navigate } from 'gatsby'
import PostListContainer from '../components/PostListContainer'
import AutoComplete from '../components/AutoComplete'
import RTE from '../components/RTE'
import { Router } from "@reach/router"
import { parseJSON, format } from "date-fns";
import { PrecisionManufacturing } from '@mui/icons-material';
const { useEffect } = React;


const myTheme = createTheme({

})

const SomeSubPage = props => {
    const { isAuth, env } = useAuth()
    const [post, setPosts] = useState([])
    const refreshData = async () => {
        const [result] = await Parse.Cloud.run('queryItems', {
            type: `post`,
            query: {
                equalTo: ['tag.slug', props.id]
            }
        })
        setPosts(result || [])
    }

    const handleComment = async (richtext) => {
        await Parse.Cloud.run('post', {
            title: post?.data?.title,
            data: {
                comment: richtext
            }
        })
        refreshData()
    }

    const handleThread = async (richtext) => {
        await Parse.Cloud.run('post', {
            title: post?.data?.title,
            data: {
                thread: richtext
            }
        })
        refreshData()
    }

    const deleteDataParts = async (title, key, data) => {

        await Parse.Cloud.run('deletePostData', {
            title: title,
            key: key,
            id: data._id
        })
        refreshData()
    }

    const handleDeleteComment = async (richtext) => {
        await Parse.Cloud.run('post', {
            title: post?.data?.title,
            data: {
                comment: richtext
            }
        })
        refreshData()
    }

    const handlePostParse = (posts) => {
        return posts?.map(e => ({ title: e.content, ...e })) || []
    }

    const handleThreadParser = (thread) => {
        return thread?.map(e => ({ title: "AI TCM", ...e })) || []
    }

    useEffect(async () => {
        refreshData()
    }, [])
    return <>

        <PostListContainer cardProps={{ noAvatar: true }} data={[{ title: post?.data?.title }]}></PostListContainer>
        {
            !post?.data?.threads?.length && <PostListContainer data={[{
                rawContent: <Box display={"flex"} justifyContent={"center"} alignItems={"center"} flexDirection={"column"}>
                    <h3>Hold on a sec, Our AI will be replying soon.</h3>
                    <PrecisionManufacturing sx={{ fontSize: 128 }} />
                </Box>
            }
            ]}></PostListContainer>
        }
        {
            <PostListContainer
                cardProps={{ handleDelete: (data) => deleteDataParts(post?.data?.title, 'threads', data), isAuth: isAuth()?.role === 'admin' }}
                data={handleThreadParser(post?.data?.threads)}></PostListContainer>
        }
        {
            isAuth()?.role === 'admin' && <RTE onPost={handleThread} />
        }
        <h2>Comments</h2>
        <PostListContainer

            cardProps={{ handleDelete: (data) => deleteDataParts(post?.data?.title, 'comments', data), isAuth: isAuth()?.role === 'admin' }}
            data={handlePostParse(post?.data?.comments)}>

        </PostListContainer>
        <RTE onPost={handleComment} />
    </>
}


const IndexPage = ({ data, location }) => {

    return (
        <>
            <Router>
                <SomeSubPage path="/post/:id" />
            </Router>
        </>)
}

export default IndexPage
